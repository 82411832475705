import React, { Component } from 'react';
import Layout from '../components/Layout';
import {AiOutlineQuestion,AiOutlineCloudServer} from 'react-icons/ai';
import {IoMdImages} from 'react-icons/io';
import {MdLoop} from 'react-icons/md';
import {Helmet} from 'react-helmet';
import Img from 'gatsby-image';
import {graphql} from 'gatsby';

class Makeweb extends Component {
    render() {
        const { data } = this.props
        return (
            <div className="makeweb">
                <Helmet>
                    <title>ทำเว็บไซต์</title>
                </Helmet>
                <Layout>
                <div className="header-page">
                    <div className="header-page-text">
                        ทำเว็บไซต์
                    </div>
                    <div className="header-page-overay"></div>
                    <Img fluid={data.file.childImageSharp.fluid} alt="ทำเว็บไซต์" className="header-page-img" />
                </div>
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-12 text-center">
                            <h3>ทำไมถึงต้องทำเว็บกับเรา <AiOutlineQuestion className="icon-50" /></h3>
                        </div>
                        <div className="col-12">
                            <p className="indent-50 font-size-22">
                                ด้วยประสบการณ์การพัฒนาเว็บไซต์มาหลายปี ไม่ว่าจะเป็นเว็บไซต์โปรไฟล์บริษัท เว็บไซต์ธนาคาร หรือจะเป็นเว็บไซต์เกี่ยวกับระบบทางธุรกิจต่างๆเราก็ทำมาหมดแล้ว
                                เรารับรองได้ว่าถ้าไว้วางใจที่จะให้เราทำเว็บไซต์ให้กับคุณ คุณจะได้เว็บไซต์ที่สวยงาม ทันสมัย และตอบโจทย์สำหรับธุรกิจคุณแน่นอน สิ่งที่คุณจะได้ คือ
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                            <div className="d-flex flex-column">
                                <div className="text-center">
                                    <IoMdImages className="icon-150" />
                                </div>
                                <div className="text-center">
                                    <h4>การออกแบบ</h4>
                                </div>
                                <div>
                                    <p className="indent-50 font-size-22">
                                        การออกแบบเว็บไซต์เราจะเน้นให้ดูทันสมัย เรียบง่าย ผู้ใช้งานสามารถใช้งานได้ง่าย และตอบโจย์ต่อความต้องการของลูกค้า
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                            <div className="d-flex flex-column">
                                <div className="text-center">
                                    <AiOutlineCloudServer className="icon-150" />
                                </div>
                                <div className="text-center">
                                    <h4>เทคโนโลยี</h4>
                                </div>
                                <div>
                                    <p className="indent-50 font-size-22">
                                        เลือกใช้เทคโนโลยีที่เป็นมาตรฐานของการทำเว็บไซต์ และมีคนใช้งานทั่วโลก รวมไปถึงเทคโนโลยีที่ลูกค้าต้องการ
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                            <div className="d-flex flex-column">
                                <div className="text-center">
                                    <MdLoop className="icon-150" />
                                </div>
                                <div className="text-center">
                                    <h4>Support</h4>
                                </div>
                                <div>
                                    <p className="indent-50 font-size-22">
                                        ดูแลลูกค้าตลอด 24 ชั่วโมง มีปัญหาหรือติดต่อสอบถามเกี่ยวกับเว็บไซต์ได้ตลอดเวลา
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </Layout>
            </div>
        );
    }
}

export default Makeweb;

export const query = graphql`
query{
      file(relativePath: {eq: "makeweb.jpg"}) {
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      }
  }  
`